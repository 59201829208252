import React, { useState } from "react"
import styled from "styled-components";

const StyledWarning = styled.div`
    background-color: pink;
    color: darkred;
    margin: -20px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 0px !important;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;

    &> span {
      flex: 1;
      margin-left: calc(32px + 1em + 4px);
    }
}
`;

const StyledButton = styled.button `
   background: transparent;
   border: 2px solid darkred;
   width: 32px;
   height: 32px;
   padding: 0;
   box-shadow: 3px 3px 0 #ff7a7a;
   line-height: 1;
   cursor: pointer;

   &:hover {
     background-color: darkred;
     color: white;
   }
`

const warning = {
    en: "Warning: this website is archived and in maintance mode, outdated stuff ahead.",
    pt: "Aviso: este website está arquivado e em modo de manutenção, coisas desatualizadas adiante.",
}

const Warning = (props) => {
    const [visible, setVisible] = useState(true);

    const handleDismiss = () => {
        setVisible(false);
    }
    return (
        <>
        {visible &&
        <StyledWarning>
            <span>{warning[props.lang]}</span>
            <StyledButton onClick={handleDismiss}>✕</StyledButton>
        </StyledWarning>
        }
        </>
    )
}

export default Warning;
